<template>
  <div class="row justify-center bg-grey-3">
    <q-card flat bordered style="width:300px">
      <q-tabs
        v-model="tab"
        no-caps
        indicator-color="orange"
        class="bg-primary text-white"
      >
        <q-tab name="siswa" label="PER-SISWA" />
        <q-tab name="kelas" label="PER-TANGGAL" />
      </q-tabs>
      <q-tab-panels v-model="tab">
        <q-tab-panel name="siswa" class="q-pa-none">
          <div class="q-gutter-md q-pa-md">
            <q-select
              dense
              outlined
              v-model="selKelas"
              label="Kelas"
              :options="kelas"
              @input="
                (tanggal = []),
                  (selSiswa = null),
                  (kehadiranKelas = []),
                  (selTanggal = null),
                  (mapel = []),
                  (selMapel = null),
                  getMapelInKelas()
              "
            />
            <q-select
              dense
              outlined
              stack-label
              v-model="selMapel"
              label="Mata Pelajaran"
              :disable="selKelas == null"
              :options="mapel"
              @input="
                (tanggal = []),
                  (selSiswa = null),
                  (kehadiranKelas = []),
                  (selTanggal = null),
                  getListSiswa()
              "
            />
            <q-select
              dense
              outlined
              v-model="selSiswa"
              label="Siswa"
              :options="siswa"
              :disable="selKelas == null || selMapel == null"
              @input="getTanggalSiswa()"
            />
          </div>

          <q-markup-table
            flat
            wrap-cells
            dense
            separator="horizontal"
            class="stickyTable no-border-radius q-mt-lg bg-grey-4"
            style="height:calc(100vh - 380px);"
          >
            <thead class="bg-indigo-5 text-white text-left">
              <tr class="text-left">
                <th>tanggal</th>
                <th style="max-width:100px"></th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <tr v-if="selKelas == null">
                <td class="text-center" colspan="2">
                  Silahkan pilih Kelas Dahulu
                </td>
              </tr>
              <tr v-else-if="selSiswa == null">
                <td class="text-center" colspan="2">
                  Silahkan pilih Siswa dahulu
                </td>
              </tr>
              <tr v-else-if="tanggal.length == 0">
                <td class="text-center" colspan="2">
                  Belum ada Input Absensi untuk siswa
                  {{ selSiswa.label }}
                </td>
              </tr>
              <tr v-for="(val, i) in tanggal" :key="i">
                <td>{{ val.tgl | moment("DD-MMM-YYYY") }}</td>
                <td style="min-width:100px">
                  <q-select
                    outlined
                    dense
                    v-model="val.status"
                    :options="opsiStatus"
                  />
                </td>
              </tr>
            </tbody>
          </q-markup-table>
        </q-tab-panel>
        <q-tab-panel name="kelas" class="q-pa-none">
          <div class="q-pa-md q-gutter-md">
            <q-select
              dense
              outlined
              v-model="selKelas"
              label="Kelas"
              :options="kelas"
              @input="
                (tanggal = []),
                  (selSiswa = null),
                  (kehadiranKelas = []),
                  (selTanggal = null),
                  (mapel = []),
                  (selMapel = null),
                  getMapelInKelas(),
                  getListSiswa()
              "
            />
            <q-select
              dense
              outlined
              stack-label
              v-model="selMapel"
              label="Mata Pelajaran"
              :disable="selKelas == null"
              :options="mapel"
              @input="
                (tanggal = []),
                  (selSiswa = null),
                  (kehadiranKelas = []),
                  (selTanggal = null)
              "
            />
            <q-input
              dense
              :disable="selMapel == null"
              outlined
              readonly
              v-model="selTanggal"
            >
              <template v-slot:prepend>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date
                      @input="getKehadiranList()"
                      v-model="selTanggal"
                      mask="YYYY-MM-DD"
                    >
                      <div class="row items-center justify-end">
                        <q-btn v-close-popup label="OK" color="primary" flat />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>
          <q-card-actions
            v-if="kehadiranKelas.length > 0"
            class="q-pa-none"
            align="stretch"
          >
            <q-btn
              flat
              dense
              class="full-width"
              label="Hapus Absensi"
              color="negative"
              @click="beforeDel = true"
            ></q-btn>
          </q-card-actions>
          <q-markup-table
            flat
            wrap-cells
            dense
            separator="horizontal"
            class="stickyTable no-border-radius q-mt-lg bg-grey-4"
            style="height:calc(100vh - 380px);"
          >
            <thead class="bg-indigo-5 text-white text-left">
              <tr class="text-left">
                <th>Nama</th>
                <th style="max-width:100px"></th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <tr v-if="selKelas == null">
                <td class="text-center" colspan="2">
                  Silahkan pilih Kelas Dahulu
                </td>
              </tr>
              <tr v-else-if="selTanggal == null">
                <td class="text-center" colspan="2">
                  Silahkan pilih Tanggal dahulu
                </td>
              </tr>
              <tr v-else-if="kehadiranKelas.length == 0">
                <td class="text-center" colspan="2">
                  Belum ada Input Absensi di tanggal ini untuk kelas
                  {{ selKelas.label }}
                </td>
              </tr>
              <tr v-for="(val, i) in kehadiranKelas" :key="i">
                <td>{{ val.nama }}</td>
                <td style="min-width:100px">
                  <q-select
                    outlined
                    dense
                    v-model="val.status"
                    :options="opsiStatus"
                  />
                </td>
              </tr>
            </tbody>
          </q-markup-table>
        </q-tab-panel>
      </q-tab-panels>
      <q-card-actions class="q-pa-none">
        <q-btn
          filled
          :disable="
            (tab == 'siswa' && tanggal.length == 0) ||
              (tab == 'kelas' && kehadiranKelas.length == 0)
          "
          color="negative"
          v-can="['WALAS', 'MAPEL']"
          class="full-width no-border-radius"
          @click="simpan()"
          >simpan</q-btn
        >
      </q-card-actions>
    </q-card>
    <q-dialog v-if="selMapel != null" v-model="beforeDel" persistent>
      <q-card>
        <q-card-section>
          Tulis "{{ selMapel.label }}" tanpa tanda kutip untuk menghapus absen
          pada tanggal dan mapel terpilih
          <q-input outlined dense v-model="keyword"></q-input>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="close" v-close-popup></q-btn>
          <q-btn
            unelevated
            label="Hapus"
            :disable="keyword.toLowerCase() != selMapel.label.toLowerCase()"
            color="negative"
            @click="hapus"
            v-close-popup
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      user: {},
      beforeDel: false,
      keyword: "",

      selTanggal: null,
      kehadiranKelas: [],

      selSiswa: null,
      tanggal: [],
      siswa: [],

      tab: "siswa",
      confirm: true,
      opsiStatus: ["Hadir", "Izin", "Sakit", "Alfa"],

      selKelas: null,
      kelas: [],

      selMapel: null,
      mapel: [],

      searchTerm: "",
    };
  },
  async mounted() {
    await this.getUser();
    await this.getListKelas();
  },
  methods: {
    ...mapGetters(["getCurrentUser"]),
    async getUser() {
      this.user = await this.getCurrentUser();
    },
    async hapus() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http.post(
        `/penilaian/riwayat_absen/hapusabsen`,
        this.kehadiranKelas
      );
      this.$q.notify({
        message: `Absensi pada mapel dan tanggal terpilih pada ${this.selKelas.label} telah dihapus!`,
        color: "negative",
      });
      this.$q.loading.hide();
      this.getKehadiranList();
      this.selMapel.label = "";
    },
    async getMapelInKelas() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(
        `/penilaian/riwayat_absen/getmapelinkelas/${this.selKelas.value}/${this.user.id}/${this.user.id_wali_kelas}/${this.user.is_super_user}`
      );
      this.mapel = resp.data;
      this.$q.loading.hide();
    },
    async getKehadiranList() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      this.kehadiranKelas = [];
      await this.$http
        .get(
          "/penilaian/riwayat_absen/getkelas/" +
            this.selTanggal +
            "/" +
            this.selKelas.value
        )
        .then((resp) => {
          this.kehadiranKelas = resp.data;
        });
      this.$q.loading.hide();
    },
    async getListKelas() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http
        .get(
          `/penilaian/riwayat_absen/getkelas/${localStorage.getItem(
            "jenjang"
          )}/${this.user.id}/${this.user.is_guru_mapel}/${
            this.user.is_wali_kelas
          }/${this.user.is_super_user}`
        )
        .then((resp) => {
          this.kelas = resp.data;
        });
      this.$q.loading.hide();
    },
    simpan() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      if (this.tab == "siswa") {
        this.$http
          .put("/penilaian/riwayat_absen/update_status/", this.tanggal)
          .then((resp) => {
            this.$q.notify({
              message: "Absensi Berhasil Diupdate!",
              color: "positive",
            });
          });
      } else if (this.tab == "kelas") {
        this.$http
          .put("/penilaian/riwayat_absen/update_status/", this.kehadiranKelas)
          .then((resp) => {
            this.$q.notify({
              message: "Absensi Berhasil Diupdate!",
              color: "positive",
            });
          });
      }
      this.$q.loading.hide();
    },
    getListSiswa() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      this.$http
        .get("/penilaian/riwayat_absen/getsiswa/" + this.selKelas.value)
        .then((resp) => {
          this.siswa = resp.data;
        });
      this.$q.loading.hide();
    },
    async getTanggalSiswa() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(
        `/penilaian/riwayat_absen/gettgl/${
          this.selSiswa.value
        }/${localStorage.getItem("id_tahun_ajaran")}/${this.selMapel.value}`
      );
      this.tanggal = resp.data;
      this.$q.loading.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/quasar.sass";
</style>
